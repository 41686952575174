import React from "react"
import { graphql } from "gatsby"

import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import Section from "wildComponents/Section"
import Hero from "../sections/shared/Hero"
import Content from "../sections/shared/Content"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function Default(props) {
  const { data } = props
  return (
    <Layout>
      <Hero
        title={data.wpPage?.title}
        bgImage={data.wpPage?.featuredImage?.node}
      />
      <Section className="light" containerSize="lg" minH="60vh">
        <Content content={data.wpPage?.content} />
      </Section>
    </Layout>
  )
}

export default Default

export const pageQuery = graphql`
  query GET_DEFAULT_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
